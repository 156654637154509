import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link } from 'react-router-dom';
import { FiFacebook, FiLinkedin } from "react-icons/fi"

function Footer() {
  return (
    <Container maxWidth="xl">
      <Box component="div">
        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
          <Grid item xs={6} md={8}>
          <Typography variant="body1" color="gray">
            All Rights Reserved. Designed and Developed by <Link to='https://squadcloud.co' underline="none">SquadCloud</Link>.
          </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <List sx={{display: 'flex'}}>
              <Link to='https://facebook.com' underline="none">
                <ListItem  disablePadding >
                  <ListItemButton>
                    <ListItemIcon>
                      <FiFacebook /> 
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </Link>
              <Link to='/https://linkedin.com' underline="none">
                <ListItem  disablePadding >
                  <ListItemButton>
                    <ListItemIcon>
                      <FiLinkedin /> 
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </Link>
            </List>
          </Grid>
        </Grid>
      </Box>
    </Container>
    
  );
}

export default Footer;
