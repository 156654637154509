// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #191919;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiListItemIcon-root {
  min-width: 20px !important;
  color: rgb(255 255 255 / 80%) !important;
}
.MuiList-root > a {
  color: rgb(255 255 255 / 80%) !important;
  text-decoration: none !important;
}
/* Cinema */
.cinema-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10%, 1fr));
  gap: 15px;
  align-items: center;
  justify-content: center;
}
.cinema-grid > img {
  width: 100%;
}
.video-react.video-react-fluid, .video-react.video-react-16-9, .video-react.video-react-4-3 {
  padding: 0 !important;
  height: calc(100vh - 60px) !important;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;AACA;EACE,0BAA0B;EAC1B,wCAAwC;AAC1C;AACA;EACE,wCAAwC;EACxC,gCAAgC;AAClC;AACA,WAAW;AACX;EACE,aAAa;EACb,0DAA0D;EAC1D,SAAS;EACT,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,WAAW;AACb;AACA;EACE,qBAAqB;EACrB,qCAAqC;AACvC","sourcesContent":["body {\n  background-color: #191919;\n}\n.App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n.MuiListItemIcon-root {\n  min-width: 20px !important;\n  color: rgb(255 255 255 / 80%) !important;\n}\n.MuiList-root > a {\n  color: rgb(255 255 255 / 80%) !important;\n  text-decoration: none !important;\n}\n/* Cinema */\n.cinema-grid {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(10%, 1fr));\n  gap: 15px;\n  align-items: center;\n  justify-content: center;\n}\n.cinema-grid > img {\n  width: 100%;\n}\n.video-react.video-react-fluid, .video-react.video-react-16-9, .video-react.video-react-4-3 {\n  padding: 0 !important;\n  height: calc(100vh - 60px) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
