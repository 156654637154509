import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';


import './App.css';
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import Home from './components/pages/Home'

const DefaultLayout = React.lazy(() => import('./components/DefaultLayout'))

function App() {
  return (
    <BrowserRouter>
      <Suspense>
        <Routes>
          <Route path='*' element={<DefaultLayout />} /> 
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
