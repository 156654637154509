import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { FiHome, FiFilm, FiHeadphones, FiVideo, FiYoutube, FiGlobe, FiTv, FiDownload } from "react-icons/fi"
import { RiGamepadLine  } from "react-icons/ri";
import logo from '../../fundady-sm.png'

// import AdbIcon from '@mui/icons-material/Adb';

const pages = ['Home', 'Cinema', 'Music', 'Videos', 'Webseries', 'Sports', 'Gaming', 'TvOn', 'Apps'];
const settings = ['Sign In', 'Sign up'];

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <AppBar position="static">
    <Container maxWidth="xl">
      <Toolbar disableGutters>
        {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="#app-bar-with-responsive-menu"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          <Box
          component="img"
          sx={{
            width: 200
          }}
          alt="The house from the offer."
          src={logo}
        />
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            {/* <MenuIcon /> */}
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu}>
                <Typography textAlign="center"><FiHome /> {page}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
        <Typography
          variant="h5"
          noWrap
          component="a"
          href="#app-bar-with-responsive-menu"
          sx={{
            mr: 2,
            display: { xs: 'flex', md: 'none' },
            flexGrow: 1,
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
         <Box
          component="img"
          sx={{
            height: 233,
            width: 350,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt="The house from the offer."
          src={logo}
        />
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {/* {pages.map((page) => (
            <Button
              key={page}
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              <FiHome /> {page}
            </Button>
          ))} */}
          <List sx={{display: 'flex'}}>
            <Link to='/' underline="none">
              <ListItem  disablePadding >
                <ListItemButton>
                  <ListItemIcon>
                    <FiHome /> 
                  </ListItemIcon>
                  <ListItemText primary='Home' />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to='/cinema' underline="none">
              <ListItem  disablePadding >
                <ListItemButton>
                  <ListItemIcon>
                    <FiFilm /> 
                  </ListItemIcon>
                  <ListItemText primary='Cinema' />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to='/music' underline="none">
              <ListItem  disablePadding >
                <ListItemButton>
                  <ListItemIcon>
                    <FiHeadphones /> 
                  </ListItemIcon>
                  <ListItemText primary='Music' />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to='/videos' underline="none">
              <ListItem  disablePadding >
                <ListItemButton>
                  <ListItemIcon>
                    <FiVideo /> 
                  </ListItemIcon>
                  <ListItemText primary='Videos' />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to='/videos' underline="none">
              <ListItem  disablePadding >
                <ListItemButton>
                  <ListItemIcon>
                    <FiYoutube /> 
                  </ListItemIcon>
                  <ListItemText primary='Webseries' />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to='/videos' underline="none">
              <ListItem  disablePadding >
                <ListItemButton>
                  <ListItemIcon>
                    <FiGlobe /> 
                  </ListItemIcon>
                  <ListItemText primary='Sports' />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to='/videos' underline="none">
              <ListItem  disablePadding >
                <ListItemButton>
                  <ListItemIcon>
                    <RiGamepadLine /> 
                  </ListItemIcon>
                  <ListItemText primary='Game' />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link to='/videos' underline="none">
              <ListItem  disablePadding >
                <ListItemButton>
                  <ListItemIcon>
                    <FiTv /> 
                  </ListItemIcon>
                  <ListItemText primary='TvOn' />
                </ListItemButton>
              </ListItem>
            </Link>
          </List>
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </Container>
  </AppBar>
  );
}

export default Header;
